var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    _vm._g(
      {
        attrs: {
          visible: _vm.dialogVisible,
          width: _vm.isMobileScreen ? "90%" : "600px",
        },
        on: {
          "update:visible": function ($event) {
            _vm.dialogVisible = $event
          },
        },
      },
      _vm.$listeners
    ),
    [
      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
        _c("em", {
          staticClass: "iconfont icon-yuandianzhong",
          style: { color: _vm.$store.state.currentTheme.primaryColor },
        }),
        _vm._v(" " + _vm._s(_vm.$t("changePsw")) + " "),
      ]),
      _c(
        "div",
        { staticStyle: { padding: "20px" } },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-position": _vm.isMobileScreen ? "top" : "left",
                model: _vm.formData,
                rules: _vm.rules,
                "label-width": _vm.$i18n.locale === "cn" ? "80px" : "140px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("email") } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      value: _vm.formData.email,
                      disabled: "",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("newPsw"), prop: "password" } },
                [
                  _c("el-input", {
                    attrs: { type: "password" },
                    model: {
                      value: _vm.formData.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "password", $$v)
                      },
                      expression: "formData.password",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("confirmPaw"), prop: "confirmPaw" } },
                [
                  _c("el-input", {
                    attrs: { type: "password" },
                    model: {
                      value: _vm.formData.confirmPaw,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "confirmPaw", $$v)
                      },
                      expression: "formData.confirmPaw",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("verificationCode"),
                    prop: "verifyCode",
                  },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formData.verifyCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "verifyCode", $$v)
                          },
                          expression: "formData.verifyCode",
                        },
                      }),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: {
                            loading: _vm.emailCodeLoading,
                            disabled: _vm.emailCodeCountDown > 0,
                          },
                          on: { click: _vm.getCode },
                        },
                        [
                          _vm._v(" " + _vm._s(_vm.$t("getCode")) + " "),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.emailCodeCountDown > 0,
                                  expression: "emailCodeCountDown>0",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(`(${_vm.emailCodeCountDown})`) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.submit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("submit")) + " ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }